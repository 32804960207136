import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {DossierDetail, DossierFilter, DossierHistoryModel, PagedDossier} from 'src/app/model';
import { QueryStateService } from '../../shared/services/query-state.service';

@Injectable()
export class DossierService {
  private dossiersUrl = 'dossiers';

  constructor(private http: HttpClient, private queryStateService: QueryStateService) {
  }

  async getDossierPaymentPlanHistory(dossierId: string): Promise<DossierHistoryModel> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http.get<DossierHistoryModel>(`${ this.dossiersUrl }/${ dossierId }/paymentplan/history`,
      { params: queryScope as any }).toPromise();
  }

  async getDossierDetail(dossierId: string): Promise<DossierDetail> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http.get<DossierDetail>(`${ this.dossiersUrl }/${ dossierId }`,
      { params: queryScope as any }).toPromise();
  }

  async updateDossierFlowToDebtCollection(invoiceIds: Set<string>): Promise<any> {
    const invoiceIdsArray = Array.from(invoiceIds).map(id => id.toString());
    return await this.http.patch(`${this.dossiersUrl}/transfer-debtcollection`, invoiceIdsArray, {
      headers: { 'Content-Type': 'application/json' }
    }).toPromise();
  }

  async addOngoingInvoicesToCurrentDossier(invoiceIds: Set<string>, dossierId: string,): Promise<any> {
    const invoiceIdsArray = Array.from(invoiceIds).map(id => id.toString());
    return this.http.patch(`${this.dossiersUrl}/add-ongoing-invoices-to-dossier/${ dossierId }`, invoiceIdsArray, {
      headers: { 'Content-Type': 'application/json' }
    }).toPromise();
  }

  async linkDebtorToDossier(dossierId: string, debtorId: string): Promise<any> {
    const queryScope = this.queryStateService.addClientParameters({ debtorId } as any);
    return this.http.patch(`${ this.dossiersUrl }/${ dossierId }/link-debtor-to-dossier`, null,
      { observe: 'response', params: queryScope as any }).toPromise();
  }

  async deleteDossier(dossierId: string): Promise<any> {
    const params = this.queryStateService.addClientParameters({} as any) as any;
    return this.http.request('delete', this.dossiersUrl, { body: { dossierIds: [ dossierId ] }, params }).toPromise();
  }

  async getDossiers(filter: DossierFilter): Promise<PagedDossier> {
    const queryScope = this.queryStateService.addClientParameters(filter);
    return this.http.get<PagedDossier>(`${this.dossiersUrl}`, { params: queryScope as any }).toPromise();
  }

  public async resetFlow(dossierId: string, skipImmediateAction: boolean) {
    const payload = this.queryStateService.addClientParameters({
      dossierIds: [ dossierId ],
      resetScheduleReferenceDate: true,
      skipImmediateAction,
      resetSkipImmediate: true
    } as any) as any;
    return this.http.put(`${ this.dossiersUrl }/reset`, payload).toPromise();
  }
}
