import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { saveAs } from "file-saver";
import {
  ActionDefinitionAddDto,
  ActionDefinitionDetailDto,
  FilterOptions,
  FutureActionsDto,
  MessageTemplateContentDto,
  PagedPlannedAction,
  PauseActionStateModel,
  PlannedActionFilter,
  ResendModel,
  TemplatePreviewOptionsDto,
  TimelineDetailViewModel,
} from "src/app/model";
import { QueryStateService } from "../../shared/services";

@Injectable()
export class ActionDefinitionService {
  private actionsUrl = "actions";
  private actionDefinitionsUrl = "ctp";

  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService,
    private store: Store,
  ) {}

  async executePlannedAction(plannedActionId: string): Promise<object> {
    const queryParams = this.queryStateService.addClientParameters({}) as any;
    return this.http
      .post(`${this.actionsUrl}/${plannedActionId}/execute`, {
        params: queryParams,
      })
      .toPromise();
  }

  async executePlannedActions(plannedActionIds: string[]): Promise<object> {
    const queryScope = this.queryStateService.addClientParameters({}) as any;
    return this.http
      .post(`${this.actionsUrl}/execute`, plannedActionIds, { params: queryScope })
      .toPromise();
  }

  async resendPlannedAction(resendModel: ResendModel): Promise<object> {
    const payload = this.queryStateService.addClientParameters(
      resendModel,
    ) as any;
    return this.http.post(`${this.actionsUrl}/resend`, payload).toPromise();
  }

  async getPlannedActions(
    filter: PlannedActionFilter,
  ): Promise<PagedPlannedAction> {
    const queryScope = this.queryStateService.addClientParameters(filter);
    const paramsToSend = { ...queryScope } as any;
    if (filter.toDate) {
      paramsToSend.toDate = filter.toDate.toDateString();
    }
    if (filter.fromDate) {
      paramsToSend.fromDate = filter.fromDate.toDateString();
    }

    return this.http
      .get<PagedPlannedAction>(this.actionsUrl, { params: paramsToSend })
      .toPromise();
  }

  async pauseCtpActions(
    pauseActionStateModel: PauseActionStateModel,
  ): Promise<void> {
    const paramsToSend = {
      ...this.queryStateService.addClientParameters(pauseActionStateModel),
    } as any;
    if (pauseActionStateModel.plannedNotificationDate) {
      paramsToSend.plannedNotificationDate =
        pauseActionStateModel.plannedNotificationDate.toDateString();
    }
    return this.http
      .post<void>(this.actionsUrl + "/state", null, { params: paramsToSend })
      .toPromise();
  }

  async writeOffRemaining(actionIds: string[], message: string): Promise<void> {
    const queryScope = this.queryStateService.addClientParameters({
      message,
    } as any);
    const paramsToSend = { ...queryScope } as any;
    return this.http
      .patch<void>(`${this.actionsUrl}/writeoff-remaining`, actionIds, {
        params: paramsToSend,
      })
      .toPromise();
  }

  async skipPlannedActions(actionIds: string[]): Promise<void> {
    const queryScope = this.queryStateService.addClientParameters({}) as any;
    return this.http
      .post<void>(`${this.actionsUrl}/skip`, actionIds, { params: queryScope })
      .toPromise();
  }

  public async loadPlannedActions(): Promise<FutureActionsDto> {
    const queryScope = this.queryStateService.addClientParameters({}) as any;
    const dataForPlannedActions = await this.http
      .get<FutureActionsDto>(this.actionsUrl + "/futuredetail", {
        params: queryScope,
      })
      .toPromise();
    return dataForPlannedActions;
  }

  async getTimeLineItemDetail(
    plannedActionId: string,
  ): Promise<TimelineDetailViewModel> {
    const queryScope = this.queryStateService.addClientParameters({
      plannedActionId,
    } as any);
    const paramsToSend = { ...queryScope } as any;
    return this.http
      .get<TimelineDetailViewModel>(this.actionsUrl + "/timelineitemdetail", {
        params: paramsToSend,
      })
      .toPromise();
  }

  async downloadAction(plannedActionId: string) {
    this.http
      .get(`${this.actionsUrl}/${plannedActionId}/download`, {
        responseType: "blob",
      })
      .subscribe(async (data) => {
        const blob = new Blob([data], { type: "application/pdf" });
        const filename = await this.generateFilename(plannedActionId);
        saveAs(blob, filename);
      });
  }

  private async generateFilename(plannedActionId: string): Promise<string> {
    const queryScope = this.queryStateService.addClientParameters({
      plannedActionId,
    } as any);
    const paramsToSend = { ...queryScope } as any;

    const description = await this.http
      .get(`${this.actionsUrl}/${plannedActionId}/filename`, {
        params: paramsToSend,
        responseType: "text",
      })
      .toPromise();
    const date = new Date();
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const timeString = `${date.getHours().toString().padStart(2, "0")}-${date.getMinutes().toString().padStart(2, "0")}-${date.getSeconds().toString().padStart(2, "0")}`;
    return `${description} - ${dateString}_${timeString}.pdf`;
  }

  async getActionDefinitionDetail(
    actionDefinitionId: string,
  ): Promise<ActionDefinitionDetailDto> {
    const paramsToSend = this.queryStateService.addClientParameters({}) as any;
    return this.http
      .get<ActionDefinitionDetailDto>(
        `${this.actionDefinitionsUrl}/${actionDefinitionId}`,
        { params: paramsToSend },
      )
      .toPromise();
  }

  async getTemplatePreview(
    actionDefinitionId: string,
    templatePreviewOptions: TemplatePreviewOptionsDto,
  ): Promise<MessageTemplateContentDto> {
    const paramsToSend = this.queryStateService.addClientParameters(
      templatePreviewOptions,
    ) as any;
    return this.http
      .get<MessageTemplateContentDto>(
        `${this.actionDefinitionsUrl}/${actionDefinitionId}/preview`,
        { params: paramsToSend },
      )
      .toPromise();
  }

  async downloadTemplatePreview(
    actionDefinitionId: string,
    templatePreviewOptions: TemplatePreviewOptionsDto,
  ) {
    const paramsToSend = this.queryStateService.addClientParameters(
      templatePreviewOptions,
    ) as any;
    const downloadResult = await this.http
      .get(
        `${this.actionDefinitionsUrl}/${actionDefinitionId}/preview/download`,
        {
          params: paramsToSend as any,
          responseType: "blob" as "json",
        },
      )
      .toPromise();

    if (downloadResult) {
      saveAs(downloadResult, "preview.pdf");
    }
  }

  async updateTemplateContent(
    updatedTemplateContent: MessageTemplateContentDto,
  ): Promise<void> {
    return this.http
      .patch<void>(
        `${this.actionDefinitionsUrl}/content`,
        updatedTemplateContent,
        {},
      )
      .toPromise();
  }

  async updateTemplateSubject(
    updatedTemplateContent: MessageTemplateContentDto,
  ): Promise<void> {
    return this.http
      .patch<void>(
        `${this.actionDefinitionsUrl}/subject`,
        updatedTemplateContent,
        {},
      )
      .toPromise();
  }

  async updateActionDefinition(
    updatedActionDefinition: ActionDefinitionDetailDto,
  ): Promise<void> {
    return this.http
      .patch<void>(`${this.actionDefinitionsUrl}`, updatedActionDefinition, {})
      .toPromise();
  }

  async addActionDefinition(
    addActionDefinition: ActionDefinitionAddDto,
  ): Promise<void> {
    return this.http
      .post<void>(`${this.actionDefinitionsUrl}`, addActionDefinition, {})
      .toPromise();
  }

  async deleteActionDefinition(id: string) {
    return this.http
      .delete<any>(`${this.actionDefinitionsUrl}/${id}`, {
        observe: "response",
      })
      .toPromise();
  }

  async updateTimeSpans(
    id: string,
    daysToAddOrSubtract: number,
  ): Promise<void> {
    return this.http
      .patch<void>(
        `${this.actionDefinitionsUrl}/timespan`,
        {
          numberOfDays: daysToAddOrSubtract,
          actionDefinitionIds: [id],
        },
        {},
      )
      .toPromise();
  }

  async getFilterOptions(): Promise<FilterOptions> {
    const queryScope = this.queryStateService.addClientParameters({}) as any;
    return await this.http
      .get<FilterOptions>(this.actionsUrl + "/filter-options", {
        params: queryScope,
      })
      .toPromise();
  }
}
